<template>
	<div class="app-container">
		<el-row>
			<el-col :span="15">
				<el-container>
					<el-header>
						<el-form size="small" :inline="true" label-width="68px">
						  <el-form-item>
						    <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="addSb">新增</el-button> 
						  </el-form-item>
						</el-form> 
					</el-header>
					<el-main>
						<el-table v-loading="loading" :data="sblist" style="margin-top: 20px;" @row-click="selSb">
						  <el-table-column type="selection" width="55" align="center" />
						  <el-table-column label="序号" type="index" width="50" align="center">
						    <template slot-scope="scope">
						      <span>{{
						        (queryParams.pageNum - 1) * queryParams.pageSize +
						        scope.$index +
						        1
						      }}</span>
						    </template>
						  </el-table-column>
						  <el-table-column label="服务主机IP" align="center" prop="serverHost" /> 
						  <el-table-column label="端口" width="50px" align="center" prop="serverPort" /> 
						  <el-table-column label="用户名" width="60px" align="center" prop="userName" />
						  <!-- <el-table-column label="密码" align="center" prop="password" /> -->
						  <el-table-column label="客户端ID" width="85px" align="center" prop="clientId" />
						  <el-table-column label="主题" align="center" width="150px" prop="clientTopic" />
						  <el-table-column label="是否清除session" align="center" prop="clientCleanSession" >
							  <template slot-scope="scope">
								{{scope.row.clientCleanSession=='1'?'是':'否'}}
							  </template>
							</el-table-column>
						  <el-table-column label="启用状态" width="80px" align="center" prop="enableFlag">
						    <template slot-scope="scope">
						      <el-tag :type="scope.row.enableFlag=='1'?'success':'info'">{{scope.row.enableFlag=='1'?'启用':'禁用'}}</el-tag>
						    </template>
						  </el-table-column>
						  <el-table-column label="删除状态" width="80px" align="center" prop="deleted">
						    <template slot-scope="scope">
						      <el-tag :type="scope.row.deleted=='1'?'info':'success'">{{scope.row.deleted=='1'?'删除':'正常'}}</el-tag>
						    </template>
						  </el-table-column>
						  <el-table-column label="在线状态" width="80px" align="center" prop="status">
						    <template slot-scope="scope">
						      <el-tag :type="scope.row.status=='1'?'success':'info'">{{scope.row.status=='1'?'在线':'离线'}}</el-tag>
						    </template>
						  </el-table-column>
						  <el-table-column label="操作" width="180px" fixed="right" align="center" class-name="small-padding fixed-width">
						    <template slot-scope="scope">
						      <el-button size="mini" type="text" icon="el-icon-edit" @click="XgSb(scope.row)">修改</el-button>
						      <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
						      <el-button size="mini" type="text" :icon="scope.row.enableFlag=='0'?'el-icon-turn-off':'el-icon-open'" @click.stop="Qy(scope.row)">{{scope.row.enableFlag=='0'?'启用':'禁用'}}</el-button>
							  <el-button size="mini" type="text" icon="el-icon-chat-dot-round" @click="sendMess(scope.row)">发送消息</el-button>
							</template>
						  </el-table-column>
						</el-table>
					</el-main>
				</el-container>
			</el-col>
			<el-col :span="9"> 
				<el-container> 
					<el-main>
						<el-table v-loading="loading" :data="CdList" style="margin-top: 20px;height: 90vh;overflow-y: scroll;">
						  <el-table-column type="selection" width="55" align="center" />
						  <el-table-column label="序号" type="index" width="50" align="center">
						    <template slot-scope="scope">
						      <span>{{
						        (queryParams.pageNum - 1) * queryParams.pageSize +
						        scope.$index +
						        1
						      }}</span>
						    </template>
						  </el-table-column> 
						  <el-table-column label="客户端ID" align="center" prop="mqttClientId" />
						  <el-table-column label="主题" align="center" prop="clientTopic" /> 
						  <el-table-column label="消息" align="center" prop="message">
							<!-- <template slot-scope="scope"> 
						      {{JSON.parse(scope.row.message).value}}
						    </template> -->
						  </el-table-column> 
						  <el-table-column label="时间" align="center" prop="createDate"> 
							<template slot-scope="scope">
							  <span>{{ scope.row.createDate | cstToIso }}</span>
							</template>
						  </el-table-column> 
						</el-table>
					</el-main>
				</el-container>
			</el-col>
		</el-row> 
		<el-dialog :title="title" :visible.sync="open" width="900" append-to-body >
		  <el-form ref="form" :model="form" :rules="rules" label-width="100px">
		    <el-row>
				<el-col :span="12">
					<el-form-item label="服务主机IP" prop="serverHost">
					  <el-input v-model="form.serverHost" placeholder="请输入服务主机IP" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="端口" prop="serverPort">
					  <el-input v-model="form.serverPort" placeholder="请输入端口" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row> 
			<el-row>
				<el-col :span="12">
					<el-form-item label="用户名" prop="userName">
					  <el-input v-model="form.userName" placeholder="请输入用户名" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="密码" prop="password">
					  <el-input v-model="form.password" placeholder="请输入密码" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="客户端ID" prop="clientId">
					  <el-input v-model="form.clientId" placeholder="请输入客户端ID" style="width: 100%" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="主题" prop="clientTopic">
					  <el-input v-model="form.clientTopic" placeholder="请输入主题" style="width: 100%" />
					</el-form-item>
				</el-col>
			</el-row> 
			<el-row>
				<el-col :span="12">
					<el-form-item label="是否清除session" prop="clientCleanSession">
					  <!-- <el-input v-model="form.clientCleanSession" placeholder="请输入是否清除session" style="width: 100%" /> -->
					  <el-select placeholder="请选择是否清除session" v-model="form.clientCleanSession" style="width: 100%">
							<el-option label="是" value="1"></el-option>
							<el-option label="否" value="0"></el-option> 
					  </el-select> 
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="在线状态" prop="status">
					  <el-select placeholder="请选择在线状态" v-model="form.status" style="width: 100%">
						<el-option label="在线" value="1"></el-option>
						<el-option label="离线" value="0"></el-option> 
					  </el-select> 
					</el-form-item>
				</el-col>
			</el-row> 
			<el-row>  
				<el-col :span="12">
					<el-form-item label="启用状态" prop="enableFlag">
					  <el-select placeholder="请选择启用状态" v-model="form.enableFlag" style="width: 100%">
						<el-option label="启用" value="1"></el-option>
						<el-option label="禁用" value="0"></el-option> 
					  </el-select> 
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="删除状态" prop="deleted">
					  <el-select placeholder="请选择删除状态" v-model="form.deleted" style="width: 100%">
						<el-option label="正常" value="0"></el-option>
						<el-option label="删除" value="1"></el-option> 
					  </el-select> 
					</el-form-item>
				</el-col>
			</el-row>
			
			
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="submitForm">确 定</el-button>
		    <el-button @click="open=false">取 消</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import Vue from 'vue';
	// 中国标准时间转换为ISO 8601时间格式
	Vue.filter('cstToIso', function(value) {
	  if (!value) return null;
	  // 假设CST就是UTC + 8，可以通过Date对象进行时区转换 
	  return new Date(value).toLocaleString();
	});
	export default {
		data() {
			return {
				open:false, 
				queryParams: {
				  pageNum: 1,
				  pageSize: 10, 
				},
				// 表单参数
				form: {
					serverHost:null,
					serverPort:null,
					userName:null,
					password:null,
					clientId:null, 
					clientTopic:'',
					clientCleanSession:1,
					remark:null,
					enableFlag:null,
					deleted:null,
					status:null, 
				},
				// 表单校验
				rules: {
				  serverHost: [{
				    required: true,
				    message: "服务主机IP不能为空",
				    trigger: "blur"
				  }], 
				  serverPort: [{
				    required: true,
				    message: "端口不能为空",
				    trigger: "blur"
				  }], 
				  userName: [{
				    required: true,
				    message: "用户名不能为空",
				    trigger: "blur"
				  }], 
				  password: [{
				    required: true,
				    message: "密码不能为空",
				    trigger: "blur"
				  }], 
				  clientId: [{
				    required: true,
				    message: "客户端ID不能为空",
				    trigger: "blur"
				  }], 
				  clientTopic: [{
				    required: true,
				    message: "主题不能为空",
				    trigger: "blur"
				  }], 
				  clientCleanSession: [{
				    required: true,
				    message: "是否清除session不能为空",
				    trigger: "blur"
				  }], 
				  enableFlag: [{
				    required: true,
				    message: "启用状态不能为空",
				    trigger: "blur"
				  }], 
				  deleted: [{
				    required: true,
				    message: "删除状态不能为空",
				    trigger: "blur"
				  }], 
				} ,
				title:'',
				sblist:[], 
				CdList:[],
				sendval:'{"scadaid": "3940650016309261","value": 970,"timestamp": 1686117125568,"datatype": "state"}'
			}
		}, 
		created(){
			this.getList() 
		},
		methods:{ 
			/** 搜索按钮操作 */
			handleQuery() {
			  this.queryParams.pageNum = 1;
			  this.getList();
			},
			/** 重置按钮操作 */
			resetQuery() {
			  this.resetForm("queryForm");
			  this.handleQuery();
			},
			getList(){
				this.loading = true;
				this.getRequest('/mqtt-server/getAll',this.queryParams).then(resp =>{
				    if (resp){ 
						this.sblist=resp.data.mqttServer;
						console.log(resp.data)
						this.loading = false;
				    }
				})
			},
			selSb(row,event,column){ 
				console.log(row);
				this.deviceId=row.clientId;
				// this.selSbmessage=row;
				// clearInterval(this.timer)
				this.timer = setInterval(this.getdata,10000)
				this.getdata();
			},
			getdata(){
				this.getRequest('/mqtt-server/getMessageList?mqttClientId='+this.deviceId).then(resp =>{
				    if (resp){ 
						console.log(resp)
						this.CdList=resp.data.mqttMessageList;
				    }
				})
			},
			sendMess(row){ 
				console.log(row);
				this.$prompt('你要发送的消息 clientId 为'+row.clientId+"主题为 "+ row.clientTopic, '提示', {
				  inputValue: this.sendval,
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  inputType: "textarea",
				  customClass: 'textareaBox'  
				}).then(({ value }) => {
				  let setMess={
				    "clientTopic": row.clientTopic,
					"message": value,    
					"clientId":row.clientId
				  }
				  this.postRequest('/mqtt-server/sendMessage',setMess).then(resp => {
				  	if (resp){
				  		if(resp.data.msg=='success'){
							this.$message({
							  type: 'success',
							  message: '消息发送成功'
							});
							this.getdata();
						}
				      }
				  });
				}).catch(() => {
				  this.$message({
				    type: 'info',
				    message: '取消发送'
				  });
				});
			},
			CdCdsee(){  
				console.log(this.CdList);
				if(this.CdList.length!=0){ 
					
				}else{
					clearInterval(this.timer)
				} 
			},
			reset() {
			  this.form = {
			    serverHost:null,
			    serverPort:null,
			    userName:null,
			    password:null,
			    clientId:null, 
			    clientTopic:'',
			    clientCleanSession:1,
			    remark:null,
			    enableFlag:null,
			    deleted:null,
			    status:null, 
				};
			  this.resetForm("form");
			},
			Cdreset() {
			  this.Cdform = {
			    deviceId:null,
			    pointName:null,
			    pointIden:null,
			    registerAddress:null,
			    pointType:null,
			    collectTime:null,
			    type:null,
			    controlCode:null,
			    offset:null,
			    multiplier:null,
			    readNumber:null,
			    pointDecimal:null
				}; 
			},
			addSb(){
				this.title="新增设备"
				this.open=true;
				this.reset()
			},
			XgSb(row){
				console.log(row)
				this.title="修改设备"
				this.open=true;
				this.reset(); 
				row.deleted=row.deleted==true?'删除':'正常'
				row.enableFlag=row.enableFlag==true?'启动':'禁用'
				row.clientCleanSession=row.clientCleanSession==true?'1':'0'
				this.form=row; 
			},
			submitForm(){
				this.$refs["form"].validate(valid => {
				  if (valid) {
					  console.log(this.form)
				    this.form.deleted=this.form.deleted=='正常'?'false':'true'
					this.form.enableFlag=this.form.enableFlag=='启动'?'true':'false'
					this.form.clientCleanSession=this.form.clientCleanSession=='是'?'true':'false';
					if (this.form.id != null) {
				      //编辑
					  this.postRequest('/mqtt-server/update',this.form).then(resp => {
						if (resp){
							this.getList()
							this.open = false;
					      }
					  });
				    } else {
				      //新增
					  this.postRequest('/mqtt-server/add',this.form).then(resp => {
					      if (resp){
							this.getList()
							this.open = false;
					      }
					  });
				    }
				  }
				});
			}, 
			CdsubmitForm(){
				this.$refs["Cdform"].validate(valid => {
				  if (valid) {  
					if (this.Cdform.id != null) {
				      //编辑
					  this.postRequest('/modbus-point/batchUpdate',[this.Cdform]).then(resp => {
						if (resp){
						console.log(resp)
							this.getdata()
							this.Cdopen = false;
					      }
					  });
				    } else {
				      //新增
					  this.postRequest('/modbus-point/save',[this.Cdform]).then(resp => {
					      if (resp){
					        console.log(resp)
							this.getdata()
							this.Cdopen = false;
					      }
					  });
				    }
				  }
				});
			},
			handleDelete(row) {
			  this.$confirm('此操作将永久删除此设备, 是否继续?', '提示', {
			      confirmButtonText: '确定',
			      cancelButtonText: '取消',
			      type: 'warning'
			  }).then(() => { 
			      this.postRequest('/mqtt-server/delete?id='+row.id).then(resp =>{
			          if (resp){
			              this.getList();
			          }
			      });
			  }).catch(() => {
			      this.$message({
			          type: 'info',
			          message: '已取消删除'
			      });
			  }); 
			},
			handleAdd(){
				this.Cdtitle="新增测点"
				this.Cdreset()
				this.Cdform.deviceId=this.deviceId;
				this.Cdopen=true;
				
			},
			handleUpdate(row){
				console.log(row);
				this.Cdtitle="编辑测点" 
				this.Cdreset()
				this.Cdopen=true;
				row.pointType=row.pointType
				this.Cdform=row;
			},
			Qy(row){
				let txt=row.enableFlag=='0'?'是否启用此设备?':'是否禁用此设备?'
				let enable=row.enableFlag=='0'?'1':'0';
				this.$confirm(txt, '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => { 
				    this.postRequest('/mqtt-server/enable?id='+row.id+'&enable='+enable).then(resp =>{
				        if (resp){
				            this.getList();
				        }
				    });
				}).catch(() => {
				    this.$message({
				        type: 'info',
				        message: '已取消'
				    });
				}); 
			},
			CdhandleDelete(row){
				this.$confirm('此操作将永久删除此设备, 是否继续?', '提示', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => { 
				    this.getRequest('/modbus-point/delete?id='+row.id).then(resp =>{
				        if (resp){
				            this.getdata();
				        }
				    });
				}).catch(() => {
				    this.$message({
				        type: 'info',
				        message: '已取消删除'
				    });
				}); 
			},
			// 表单重置
			reset() {
			  this.form = {
			    id: null,
			    dbpc: null,
			    dbrq: null,
			    dbsl: null,
			    blry: null,
			    shr: null,
			    remark: null, 
			  }; 
			},
			// 取消按钮
			cancel() {
			  this.open = false;
			  this.reset();
			},
		},
		beforeDestroy() {
			clearInterval(this.timer)
		}, 
	} 
</script>

<style>
</style>